<template>
  <div v-show="searchActive" :class="{ 'search-form': true, mobile: isMobile }">
    <div class="search-wrapper">
      <input
        ref="search"
        aria-label="Search. Clickable results will autopopulate after this field as you type."
        aria-autocomplete="list"
        :aria-owns="googleSearch ? 'searchResults' : 'st-injected-content'"
        role="combobox"
        autocomplete="off"
        placeholder="Search"
        class="st-default-search-input"
        type="search"
        name="keys"
        value
        size="15"
        maxlength="128"
        v-model="searchInput"
        @keydown="handleSearchResultsNavigation($event)"
      />
      <searchSVG v-if="isMobile" />
    </div>
    <div v-if="googleSearch" class="search__results" ref="searchResults">
      <div class="sr-only" id="announce" aria-live="assertive">
        {{ this.searchResults.length }} results found. Use up and down arrows to review.
      </div>
      <ul class="search__results__list" id="searchResults" role="listbox" v-if="searchResults.length > 0">
        <li
          role="option"
          :id="`result-${index + 1}`"
          v-for="(loc, index) in searchResults"
          :key="index"
          class="search__results__list-item"
        >
          <button
            tabindex="-1"
            :class="{
              search__results__button: true,
              'search__results__button--active': activeSearchResultIndex === index,
            }"
            @click="goToLocation(loc.id)"
          >
            <h3 class="search__results__item-name">{{ loc.main }}</h3>
            <p class="search__results__item-loc">{{ loc.secondary }}</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import searchSVG from '@/assets/images/Header/searchIconWhite.svg';

export default {
  name: 'HeaderSearch',
  components: { searchSVG },
  props: {
    headerNavItems: { required: true },
    searchActive: { required: true },
    closeSearchbar: { required: false },
    isMobile: { required: false },
  },
  data() {
    return {
      searchIndex: -1,
      searchInput: null,
      searchResults: [],
      activeSearchResultIndex: 0,
      swiftypeResults: null,
      checkSwiftypeInterval: null,
      googleSearch: false,
      placePredictionTimeout: null,
    };
  },
  computed: {
    google() {
      return this.$store.state.map.googleInstance;
    },
  },
  methods: {
    updateSearchIndex() {
      let newIndex = -1;
      for (let i = 0; i < this.headerNavItems.length; i += 1) {
        if (this.$route.name === `${this.headerNavItems[i].linkTo}-en`) {
          newIndex = i;
          break;
        } else if (
          this.headerNavItems[i].links &&
          this.headerNavItems[i].links.map((link) => `${link.linkTo}-en`).includes(this.$route.name)
        ) {
          newIndex = i;
          break;
        }
      }
      this.searchIndex = newIndex;
    },
    getPreviousSearchResult() {
      const lastResultIndex = this.searchResults.length - 1;

      this.activeSearchResultIndex =
        this.activeSearchResultIndex - 1 < 0 ? lastResultIndex : this.activeSearchResultIndex - 1;
    },
    getNextSearchResult() {
      const lastResultIndex = this.searchResults.length - 1;

      this.activeSearchResultIndex =
        this.activeSearchResultIndex + 1 > lastResultIndex ? 0 : this.activeSearchResultIndex + 1;
    },
    handleSearchResultsNavigation(e) {
      if (this.googleSearch) {
        switch (e.key) {
          case 'ArrowUp':
            this.getPreviousSearchResult();
            break;

          case 'ArrowDown':
            this.getNextSearchResult();
            break;

          case 'Enter':
            this.goToLocation(this.searchResults[this.activeSearchResultIndex].id);

            setTimeout(() => {
              document.querySelector('.st-ui-close-button').click();
            }, 100);

            break;

          default:
            break;
        }
      }
    },
    goToLocation(id) {
      this.$store.commit('map/setLocationID', id);
      if (this.$route.name === 'locate-charger-en') {
        this.$store.dispatch('map/goToLocation', id).then(() => {
          this.$nextTick(() => {
            this.$store.commit('map/setIsSearchActive', false);
            this.$root.$emit('expandStationList');
          });
        });
        this.$store.commit('map/setLocationID', null);
        if (this.closeSearchbar) {
          this.closeSearchbar();
        }
      } else {
        this.$router.push({ name: 'locate-charger-en', query: { search: this.searchInput } });
      }
      this.googleSearch = false;
      this.searchInput = null;
      clearInterval(this.checkSwiftypeInterval);
      this.checkSwiftypeInterval = null;
    },
    checkSwiftypeResults() {
      if (document.querySelector('.st-ui-result')) {
        this.googleSearch = false;
      } else if (this.searchResults.length > 0) {
        this.googleSearch = true;
      }
    },
    addPaginationA11yToSwiftypeResults() {
      if (document.querySelector('#st-injected-content')) {
        const pagination = document.querySelectorAll('.st-ui-pagination-number-link');
        const active = document.querySelector('.active');
        active.setAttribute('aria-current', 'page');
        pagination.forEach((p, index) => {
          const page = document.createElement('span');
          const number = document.createElement('span');
          page.textContent = ' page ';
          number.textContent = `${index + 1}`;
          page.className = 'sr-only';
          p.addEventListener('click', () => p.setAttribute('aria-current', 'page'));
          p.innerHTML = '';
          p.appendChild(page);
          p.appendChild(number);
        });
      }
    },
    throttlePlacePredictions() {
      clearTimeout(this.placePredictionTimeout);
      this.placePredictionTimeout = setTimeout(async () => {
        this.searchResults = await this.$store.getters['map/getPlacePredictions'](this.searchInput);
      }, 300);
    },
  },
  watch: {
    async searchInput() {
      if (this.searchInput && this.searchInput.length >= 1) {
        this.swiftypeResults = Array.from(document.querySelectorAll('.st-ui-result'));
        this.throttlePlacePredictions();

        if (!this.checkSwiftypeInterval) {
          this.checkSwiftypeInterval = setInterval(() => {
            this.checkSwiftypeResults();
          }, 1000);
        }
      }
    },
    $route() {
      this.updateSearchIndex();
      this.addPaginationA11yToSwiftypeResults();
      clearInterval(this.checkSwiftypeInterval);
      this.checkSwiftypeInterval = null;

      if (this.closeSearchbar) {
        this.closeSearchbar();
      }
    },
  },
  async mounted() {
    this.updateSearchIndex();

    /* eslint-disable */
    if (!window.PRERENDER_INJECTED) {
      // if google hasn't been instantiated yet then load it.
      if (!this.google) {
        const loader = new Loader({
          apiKey: 'AIzaSyD2Wl8iyEHWZM40TK95gVo-DWFGix6TAOA',
          libraries: ['places', 'geometry', 'marker'],
        });
        const googleInstance = await loader.load();
        this.$store.commit('map/setGoogleInstance', googleInstance);
        this.$store.commit('map/setGoogleAutocomplete', new this.google.maps.places.AutocompleteService());
        this.$store.commit('map/setGoogleSessionToken', new this.google.maps.places.AutocompleteSessionToken());
      }

      // swiftype
      // need to disable linter rules for this outside script
      (function (w, d, t, u, n, s, e) {
        w['SwiftypeObject'] = n;
        w[n] =
          w[n] ||
          function () {
            (w[n].q = w[n].q || []).push(arguments);
          };
        s = d.createElement(t);
        e = d.getElementsByTagName(t)[0];
        s.async = 1;
        s.src = u;
        e.parentNode.insertBefore(s, e);
      })(window, document, 'script', '//s.swiftypecdn.com/install/v2/st.js', '_st');

      _st('install', process.env.VUE_APP_SWIFTYPE_KEY, '2.0.0');

      //  OneTrust Cookies Consent Notice start for www.electrifyamerica.com
      const cookiesScript = document.createElement('script');
      cookiesScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
      cookiesScript.setAttribute('type', 'text/javascript');
      cookiesScript.setAttribute('charset', 'UTF-8');
      cookiesScript.setAttribute('data-domain-script', process.env.VUE_APP_COOKIE_SCRIPT);
      document.head.appendChild(cookiesScript);
      // eslint-disable-next-line
      function OptanonWrapper() {}
      // OneTrust Cookies Consent Notice end for www.electrifyamerica.com
    }
    /* eslint-enable */
  },
};
</script>

<style lang="scss" scoped>
.search-form {
  display: flex;
  justify-content: center;

  min-width: 600px;
  width: 100%;

  margin-top: 10px;

  text-align: right;

  .search-wrapper {
    position: relative;

    @media (max-width: 1130px) {
      width: 80%;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 14px;

      transform: translateY(-50%);

      height: 20px;
      width: 20px;
    }
  }

  input {
    width: 400px;

    padding: 7px 11px 7px 48px;

    background: $c-primary;
    border: none;
    border-bottom: 1px solid rgba($c-primary-background, 0.5);
    border-radius: 0%;

    color: #fff;
    text-transform: none;
    -webkit-appearance: none;

    &:focus {
      border: 2px solid $c-highlights;
    }
    &::placeholder {
      text-transform: uppercase;
    }
    @media only screen and (max-width: 1130px) {
      width: 80%;
    }
  }
  input[type='search']::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.search__results {
  box-sizing: border-box;

  position: absolute;
  top: 65px;
  z-index: 100;

  max-width: 443px;
  width: 100%;
  height: fit-content;

  margin: 0 auto;

  background-color: $c-primary-background;
  border-bottom-left-radius: $global-border-radius;
  border-bottom-right-radius: $global-border-radius;

  text-align: left;

  &__header {
    background-color: $c-border;

    h3 {
      margin: 0;
      padding: 0 16px;

      color: $c-gray-dark;
      font-size: 16px;
      font-weight: $fw-light;
    }
  }

  &__list {
    list-style: none;

    max-height: 375px;
    width: 100%;

    margin: 0;
    padding: 0;

    overflow: auto;
  }

  &__list-item {
    margin: 0;
    padding: 4px;

    background: $c-primary-background;
    border-top: 1px solid $c-border;
  }

  &__item-name {
    margin: 0;

    font-size: 16px;
    font-weight: $fw-medium;
  }

  &__item-loc {
    width: 100%;

    margin: 0;

    color: $c-gray-dark;
    font-size: 11px;
  }

  &__button {
    display: block;

    width: 100%;

    padding: 12px 8px;

    background-color: $c-primary-background;
    border: 2px solid transparent;
    color: $c-primary-light;
    text-align: inherit;
  }

  &__button--active {
    border: 2px solid $c-highlights;
  }
}

.mobile.search-form {
  box-sizing: border-box;

  display: block;

  position: relative;

  width: 100%;
  min-width: auto;
  text-align: left;

  .search-wrapper {
    width: 100%;
  }

  input {
    box-sizing: border-box;

    width: 100%;
    height: 60px;

    &::placeholder {
      color: $c-primary-background;
      font-size: 16px;
      text-transform: none;
    }
  }
}
</style>
