var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:{
    'nav-item': true,
    open: _vm.isOpen,
    'active-page': _vm.$route.name === ((_vm.navItem.linkTo) + "-en"),
    'on-nested-page': _vm.navItem.nestedLinks.map(function (link) { return ((link.linkTo) + "-en"); }).includes(_vm.$route.name),
  }},[(_vm.navItem.nestedLinks && _vm.navItem.nestedLinks.length > 0)?[_c('button',{class:{ 'nav-item__trigger': true },attrs:{"id":((_vm.navItem.name.toLowerCase().replace(' ', '-')) + "-header"),"aria-control":((_vm.navItem.name.toLowerCase().replace(' ', '-')) + "-panel"),"aria-expanded":_vm.isOpen},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('span',[_vm._v(_vm._s(_vm.navItem.name))]),_c('span',{staticClass:"nav-item__caret"},[_c('HeaderCaret',{attrs:{"color":_vm.isOpen || (_vm.navItem.nestedLinks.map(function (link) { return ((link.linkTo) + "-en"); }).includes(_vm.$route.name) && !_vm.isOpen)
              ? '#35bdd1'
              : '#fff'}})],1)]),_c('div',{class:{ 'nav-accordion': true, 'nav-accordion--open': _vm.isOpen },attrs:{"role":"region","id":((_vm.navItem.name.toLowerCase().replace(' ', '-')) + "-panel"),"aria-labelledby":((_vm.navItem.name.toLowerCase().replace(' ', '-')) + "-header")}},[_c('ul',{staticClass:"nav-accordion__list"},_vm._l((_vm.navItem.nestedLinks),function(accordionItem,index){return _c('li',{key:index,staticClass:"nav-accordion__item"},[_c(accordionItem.isExternal ? 'a' : 'router-link',_vm._b({tag:"component",class:{ 'nav-accordion__link': true, 'active-page': _vm.$route.name === ((accordionItem.linkTo) + "-en") },attrs:{"target":accordionItem.isExternal ? '_blank' : null,"tabindex":_vm.isOpen ? 0 : -1},nativeOn:{"click":function($event){return (function () {
                _vm.toggleNav();
                _vm.isOpen = false;
              })($event)}}},'component',accordionItem.isExternal ? { href: accordionItem.linkTo } : { to: ("/" + (accordionItem.linkTo)) },false),[_c('div',{staticClass:"nav-accordion__content"},[_c('span',{staticClass:"nav-accordion__icon"},[_c('img',{attrs:{"src":require(("@/assets/images/Header/" + (accordionItem.icon) + "--white.png")),"alt":""}})]),_c('span',[_vm._v(_vm._s(accordionItem.name))])]),(accordionItem.isExternal)?_c('div',{staticClass:"nav-accordion__external-icon"},[_c('img',{attrs:{"src":require("@/assets/images/Global/external-link--white.png"),"alt":"External link"}})]):_vm._e()])],1)}),0)])]:_c(_vm.navItem.isExternal ? 'a' : 'router-link',_vm._b({tag:"component",staticClass:"nav-item__link",attrs:{"target":_vm.navItem.isExternal ? '_blank' : null},nativeOn:{"click":function($event){return _vm.toggleNav()}}},'component',_vm.navItem.isExternal ? { href: _vm.navItem.linkTo } : { to: ("/" + (_vm.navItem.linkTo)) },false),[_c('span',[_vm._v(_vm._s(_vm.navItem.name))])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }