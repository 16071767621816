export default {
  // Pricing specific to an individual location ID
  locationPrices: {
    // Buc-ees #43 (Leeds, AL)
    700075: {
      pass: [
        {
          level: `1-350 kW`,
          price: `0.31/kWh`,
          rate: 'kWh',
        },
      ],
    },
  },
  prices: {
    kWh: {
      pass: [
        {
          level: `1-350 kW`,
          price: `0.48/kWh`,
          rate: 'kWh',
        },
      ],
      pass_plus: [
        {
          level: `1-350 kW`,
          price: `0.36/kWh`,
          rate: 'kWh',
        },
      ],
    },
    min: {
      pass: [
        {
          level: `1-90 kW`,
          price: `0.19/min`,
          rate: 'min',
        },
        {
          level: `1-350 kW`,
          price: `0.37/min`,
          rate: 'min',
        },
      ],
      pass_plus: [
        {
          level: `1-90 kW`,
          price: `0.15/min`,
          rate: 'min',
        },
        {
          level: `1-350 kW`,
          price: `0.29/min`,
          rate: 'min',
        },
      ],
    },
  },
  // Some states are spelled out, others are codes
  states: {
    Alabama: 'min',
    Alaska: 'min',
    Arizona: 'kWh',
    Arkansas: 'kWh',
    California: 'kWh',
    Colorado: 'kWh',
    Connecticut: 'kWh',
    Delaware: 'kWh',
    'District of Columbia': 'kWh',
    Florida: 'kWh',
    Georgia: 'min',
    Hawaii: 'min',
    Idaho: 'kWh',
    Illinois: 'kWh',
    Indiana: 'kWh',
    Iowa: 'kWh',
    Kansas: 'min',
    Kentucky: 'min',
    Louisiana: 'kWh',
    Maine: 'kWh',
    Maryland: 'kWh',
    Massachusetts: 'kWh',
    Michigan: 'kWh',
    Minnesota: 'kWh',
    Mississippi: 'min',
    Missouri: 'kWh',
    Montana: 'kWh',
    Nebraska: 'min',
    Nevada: 'kWh',
    'New Hampshire': 'min',
    'New Jersey': 'kWh',
    'New Mexico': 'kWh',
    'New York': 'kWh',
    'North Carolina': 'kWh',
    'North Dakota': 'min',
    Ohio: 'kWh',
    Oklahoma: 'kWh',
    Oregon: 'kWh',
    Pennsylvania: 'kWh',
    'Rhode Island': 'kWh',
    'South Carolina': 'kWh',
    'South Dakota': 'min',
    Tennessee: 'min',
    Texas: 'kWh',
    Utah: 'kWh',
    Vermont: 'kWh',
    Virginia: 'kWh',
    Washington: 'kWh',
    'West Virginia': 'min',
    Wisconsin: 'min',
    Wyoming: 'min',

    AL: 'min',
    AK: 'min',
    AZ: 'kWh',
    AR: 'kWh',
    CA: 'kWh',
    CO: 'kWh',
    CT: 'kWh',
    DE: 'kWh',
    DC: 'kWh',
    FL: 'kWh',
    GA: 'min',
    HI: 'min',
    ID: 'kWh',
    IL: 'kWh',
    IN: 'kWh',
    IA: 'kWh',
    KS: 'min',
    KY: 'min',
    LA: 'kWh',
    ME: 'kWh',
    MD: 'kWh',
    MA: 'kWh',
    MI: 'kWh',
    MN: 'kWh',
    MS: 'min',
    MO: 'kWh',
    MT: 'kWh',
    NE: 'min',
    NV: 'kWh',
    NH: 'min',
    NJ: 'kWh',
    NM: 'kWh',
    NY: 'kWh',
    NC: 'kWh',
    ND: 'min',
    OH: 'kWh',
    OK: 'kWh',
    OR: 'kWh',
    PA: 'kWh',
    RI: 'kWh',
    SC: 'kWh',
    SD: 'min',
    TN: 'min',
    TX: 'kWh',
    UT: 'kWh',
    VT: 'kWh',
    VA: 'kWh',
    WA: 'kWh',
    WV: 'min',
    WI: 'min',
    WY: 'min',
  },
  // Some states currently don't have pricing and needs to be reflected as such
  statesWithoutPricing: ['Alaska', 'Vermont', 'West Virginia', 'AK', 'VT', 'WV'],
};
