<template>
  <header class="header" :class="{ mobile: isMobile }">
    <div class="main-list">
      <div class="container">
        <div class="left-nav">
          <router-link class="logo-container" :to="{ name: `home-en` }">
            <logo style="width: auto" />
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from '@/assets/images/Header/logo.svg';

export default {
  name: 'SimpleHeader',
  components: {
    logo,
  },
  methods: {
    goTo(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  text-transform: uppercase;
}
.pagemask {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
}
.header {
  height: 80px;
  .main-list {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #0b0f4d;
    z-index: 3;
    color: white;
    .container {
      display: flex;
      align-items: center;
      max-width: 1160px;
      justify-content: center;
      margin-top: 10px;
      height: 100%;
      max-width: 100vw;
      svg {
        cursor: pointer;
      }
      .left-nav {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 1130px) {
          width: 100%;
        }
      }
    }
  }
}
a {
  color: white;
}

.header:not(.mobile) {
  .main-list {
    height: 80px;
    box-sizing: border-box;
    padding: 0px 20px;
    .container {
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 0 auto;
      .logo-container {
        width: 115px;
        height: 50px;
        display: flex;
        svg {
          width: 270px !important;
        }
      }
    }
  }
}

.header.mobile {
  .main-list {
    height: 80px;
    display: flex;
    .container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 0 auto;
      padding: 0 40px;
      .logo-container {
        width: 115px;
        height: 50px;
      }
    }
  }
}
</style>
