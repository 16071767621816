import Locations from '../../services/Locations';
import StaticPrices from '../../constants/staticPricing';

// initial state for locations data
const state = () => ({
  // Unmodified location data returned from the API
  locations: [],
  // Location data with pricing data added
  locationMarkers: [],
  uniqueLocation: null,
  uniqueRoute: null,
  filterStateLocations: {},
  selectedStateCityLocations: {},
  selectedCityLocations: [],
  error: {},
});

/**
 * Parse Pricing Data
 *
 * Using the provided pricingData from API response, gather an object for pass
 * pricing and then mirror that structure with static Pass+ pricing.
 *
 * @todo: This Pass+ data is static for now, update when we get API endpoints that supply this data
 * @param {Object} pricingData
 * @returns Pricing Data Object
 */
const parsePricingData = (pricingData) => {
  // Failsafe in case pricingData is null
  if (pricingData === null) {
    return null;
  }

  const parsedData = { pass: [], pass_plus: [] };
  pricingData.forEach((price) => {
    if (typeof price.time !== 'undefined') {
      parsedData.pass.push({
        level: `${price.minPower}-${price.maxPower} kW`,
        price: `${price.time}/min`,
        rate: 'min',
      });
      // TODO - This Pass+ data is static for now.
      parsedData.pass_plus.push({
        level: `${price.minPower}-${price.maxPower} kW`,
        price: `${price.maxPower > 90 ? '0.24' : '0.12'}/min`,
        rate: 'min',
      });
    } else if (typeof price.energy !== 'undefined') {
      parsedData.pass.push({
        level: `${price.minPower}-${price.maxPower} kW`,
        price: `${price.energy}/kWh`,
        rate: 'kWh',
      });
      // TODO - This Pass+ data is static for now.
      parsedData.pass_plus.push({
        level: `${price.minPower}-${price.maxPower} kW`,
        price: `0.31/kWh`,
        rate: 'kWh',
      });
    }
  });

  return parsedData;
};

/**
 * Parse Static Pricing Data
 *
 * Mimicking the data structure of `parsePricingData` above, but returning static
 * pricing data instead of the pricing coming back in the API response.
 *
 * @param {String} stateName
 * @returns Pricing Data Object
 */
const parseStaticPricingData = (stateName, locationID = null) => {
  // If there is pricing information specific to this individual location ID,
  // use it instead of the state version defined below.
  if (locationID && StaticPrices.locationPrices[locationID]) {
    return StaticPrices.locationPrices[locationID];
  }

  const rate = StaticPrices.states[stateName];
  return StaticPrices.prices[rate];
};

/**
 * Getters
 *
 * Sometimes we may need to compute derived state based on store state.
 * For example filtering through a list of items and counting them
 */
const getters = {
  /**
   * Locate a Charger - Individual Location Details
   *
   * Get the location marker data from locationMarkers based on a unique identifier
   * provided in id.
   *
   * @param {int} id
   * @returns Individual location object
   */
  // eslint-disable-next-line no-shadow
  getLocationMarkerById: (state) => (id) => state.locationMarkers.find((location) => location.id === id),

  /**
   * Pricing Page - State Pricing
   *
   * Get the first public (not coming soon, commercial, or solar) location in the
   * provided state in stateName. Gather pricing data for that location and pass
   * the results back.
   *
   * @param {string} stateName
   * @returns Individual location object
   */
  // eslint-disable-next-line no-shadow
  getStatePricing: () => async (stateName) => {
    const locations = await Locations.getLocations();
    const stateLocations = locations.filter((location) => location.state === stateName && location.type === 'PUBLIC');
    if (stateLocations.length === 0) {
      return null;
    }

    const location = await Locations.getLocationDetail(stateLocations[0].id);
    location.passPricing = parsePricingData(location.pricing);
    return location;
  },

  /**
   * Pricing Page - Static State Pricing
   *
   * Mimicking the data structure of `getStatePricing` above, but returning static
   * pricing data instead of the pricing from the first public (not coming soon,
   * commercial, or solar) location in the provided state in stateName.
   *
   * @returns Individual location object with passPricing only
   */
  getStateStaticPricing: () => async (stateName) => {
    if (StaticPrices.statesWithoutPricing.includes(stateName)) {
      return null;
    }
    const location = {};
    location.passPricing = parseStaticPricingData(stateName);
    return location;
  },
};

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 */
const actions = {
  async getAllLocations({ commit }, router) {
    const locations = await Locations.getLocations();
    commit('setLocations', locations);
    commit('setLocationMarkers', { locations, router });
    commit('setStateLocations', locations);
  },
  async getLocationDetail({ commit }, locationid) {
    let location = {};
    try {
      location = await Locations.getLocationDetail(locationid);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLocationMarkerDetail', location);
  },
};

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * Vuex mutations are very similar to events: each mutation has a string type and
 * a handler. The handler function is where we perform actual state modifications,
 * and it will receive the state as the first argument
 */
const mutations = {
  // eslint-disable-next-line no-shadow
  setLocations(state, locations) {
    state.locations = locations;
  },

  // eslint-disable-next-line no-shadow
  setError(state, error) {
    state.error = error;
  },
  // eslint-disable-next-line no-shadow
  setUniqueLocation(state, marker) {
    state.uniqueLocation = marker;
  },
  // eslint-disable-next-line no-shadow
  setUniqueRoute(state, route) {
    state.uniqueRoute = route;
  },

  /**
   * Set Location Markers
   *
   * Using unmodified locations data provided in locations, create an array of locationMarkers and save to state.
   *
   * @param {Object} state - Store state passed in
   * @param {*} locations - Array of locations data coming from the API
   */
  // eslint-disable-next-line no-shadow
  setLocationMarkers(state, { locations, router }) {
    const locationMarkers = [];
    locations.forEach((res) => {
      const data = {
        id: res.id,
        position: { lat: res.coordinates.latitude, lng: res.coordinates.longitude },
        comingSoon: res.type === 'COMING_SOON' || res.status === 'UNAVAILABLE',
        distance: null,
        name: res.name,
        enterpriseName: res.suboperator && res.suboperator.name ? res.suboperator.name : null,
        city: res.city,
        state: res.state,
        zipCode: res.postalCode,
        address: res.address,
        locationid: res.siteId,
        facility: res.type,
        description: null,
        markerSelected: null,
        passPricing: [],
        stationTypes: null,
        stationCounts: {},
        CCS: null,
        active: true,
        isCommercialChargerLocation: res.type === 'COMMERCIAL',
        isL2: res.isL2,
        isCcs: res.isCcs,
        isChademo: res.isChademo,
        idleFee: null,
      };
      locationMarkers.push(data);
    });
    state.locationMarkers = locationMarkers;
    if (router.currentRoute.params.id) {
      const selected = state.locationMarkers.filter((marker) => marker.id === router.currentRoute.params.id);
      // eslint-disable-next-line prefer-destructuring
      state.uniqueLocation = selected[0];
    }
  },
  /**
   * Set State locations
   *
   * @param {Object} state - Store state passed in
   * @param {*} locations - Array of locations data coming from the API
   */
  // eslint-disable-next-line no-shadow
  setStateLocations(state, locations) {
    const stateMap = {};
    locations.forEach((e) => {
      if (e.state && e.city) {
        if (!stateMap[e.state]) {
          stateMap[e.state] = [];
        }
        stateMap[e.state].push(e);
      }
    });
    Object.keys(stateMap).forEach((item) => {
      stateMap[item].sort();
    });
    const sortedStateCityMap = {};
    Object.keys(stateMap)
      .sort()
      .forEach((i) => {
        sortedStateCityMap[i] = stateMap[i];
      });
    state.filterStateLocations = sortedStateCityMap;
  },
  /**
   * Set Selected State City locations
   *
   * @param {Object} state - Store state passed in
   * @param {*} selectedState - Selected state
   */
  // eslint-disable-next-line no-shadow
  setSelectedStateCityLocations(state, selectedState) {
    if (Object.keys(state.filterStateLocations).length > 0) {
      const filterCityLocation = Object.keys(state.filterStateLocations).filter(
        (e) => e.replaceAll(' ', '-').toLowerCase() === selectedState
      );
      const cityLocatons = filterCityLocation.length
        ? Object.values(state.filterStateLocations[filterCityLocation[0]])
        : [];
      const stateCityMap = {};
      cityLocatons.forEach((e) => {
        if (e.city) {
          if (!stateCityMap[e.city]) {
            stateCityMap[e.city] = [];
          }
          stateCityMap[e.city].push(e);
        }
      });
      Object.keys(stateCityMap).forEach((city) => {
        stateCityMap[city].sort();
      });
      const sortedCityMap = {};
      Object.keys(stateCityMap)
        .sort()
        .forEach((city) => {
          sortedCityMap[city] = stateCityMap[city];
        });
      state.selectedStateCityLocations = sortedCityMap;
    }
  },
  /**
   * Set Selected City stations
   *
   * @param {Object} state - Store state passed in
   * @param {*} selectedCity - Selected city
   */
  // eslint-disable-next-line no-shadow
  setSelectedCityLocations(state, selectedCity) {
    if (Object.keys(state.selectedStateCityLocations).length > 0) {
      const filterCityLocation = Object.keys(state.selectedStateCityLocations).filter(
        (e) => e.replaceAll(' ', '-').toLowerCase() === selectedCity
      );
      const cityLocations = filterCityLocation.length
        ? Object.values(state.selectedStateCityLocations[filterCityLocation[0]])
        : [];
      const sortedCityLocations = cityLocations.sort((a, b) => a.name.localeCompare(b.name));
      state.selectedCityLocations = sortedCityLocations;
    }
  },

  /**
   * Set Location Marker Detail
   *
   * For the provided location:
   *   - Calculate station charger counts
   *   - Parse pricing data
   *   - Store EVSEs and description
   * Save these updates to the existing state.locationMarkers object.
   *
   * @param {Object} state - Store state passed in
   * @param {*} location
   */
  // eslint-disable-next-line no-shadow
  setLocationMarkerDetail(state, location) {
    state.locationMarkers.map((marker) => {
      if (marker.id === location.id) {
        const counts = {
          ccs: 0,
          chademo: 0,
          l2: 0,
        };
        location.evses.forEach((station) => {
          if (station.connectors) {
            let type = 'ccs'; // IEC_62196_T1_COMBO
            station.connectors.some((port) => {
              if (port.standard.toLowerCase().indexOf('chademo') > -1) {
                type = 'chademo';
                return true;
              }
              if (port.standard.toLowerCase() === 'iec_62196_t1') {
                type = 'l2';
                return true;
              }
              return false;
            });
            counts[type] += 1;
          }
        });

        /**
         * Pricing was changed to display fully static data from `src/constants/staticPricing.js`
         * on 20221007. This conditional allows us to also test live API pricing data by adding
         * ?pricing=api to the URL
         */
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('pricing') === 'api') {
          marker.passPricing = parsePricingData(location.pricing);
        } else {
          marker.passPricing = parseStaticPricingData(location.state, location.id);
        }

        marker.stationCounts = counts;
        marker.stationTypes = location.evses.sort((a, b) => (a.id.split('-').pop() > b.id.split('-').pop() ? 1 : -1));
        marker.description = location.description;
        marker.idleFee = location.idleFee;
      }
      return marker;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
