const GettingStarted = () => import('@/views/EvCharging/GettingStarted.vue');
const Glossary = () => import('@/views/EvCharging/Glossary.vue');
const MobileApp = () => import('@/views/EvCharging/MobileApp.vue');
const Pricing = () => import('@/views/EvCharging/Pricing.vue');
const WhatToExpect = () => import('@/views/EvCharging/WhatToExpect.vue');
const Homestation = () => import('@/views/EvCharging/Homestation.vue');
const routes = [
  {
    path: '/homestation/',
    name: 'homestation-en',
    pathToRegexpOptions: { strict: true },
    component: Homestation,
  },
  {
    path: '/what-to-expect/',
    name: 'what-to-expect-en',
    pathToRegexpOptions: { strict: true },
    component: WhatToExpect,
  },
  {
    path: '/what-to-expect/webscreens/',
    name: 'what-to-expect-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: WhatToExpect,
  },
  {
    path: '/getting-started/',
    name: 'getting-started-en',
    pathToRegexpOptions: { strict: true },
    component: GettingStarted,
  },
  {
    path: '/glossary/',
    name: 'glossary-en',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: Glossary,
  },
  {
    path: '/glossary/:faq',
    name: 'glossary-en-faq',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: Glossary,
  },
  {
    path: '/mobile-app/',
    name: 'mobile-app-en',
    pathToRegexpOptions: { strict: true },
    component: MobileApp,
  },
  {
    path: '/pricing/',
    name: 'pricing-en',
    pathToRegexpOptions: { strict: true },
    component: Pricing,
  },
  {
    path: '/pricing/webscreens/',
    name: 'pricing-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: Pricing,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
