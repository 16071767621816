let locations = [];
const locationDetail = {};

export default {
  async getLocations() {
    await axios.get(`${process.env.VUE_APP_API_URL}/v2/locations`,).then(async (result) => {
      locations = result.data;
    });
    return locations;
  },
  async getLocationDetail(locationid) {
    if (locationDetail[locationid]) {
      return locationDetail[locationid];
    }

    await axios.get(`${process.env.VUE_APP_API_URL}/v2/locations/${locationid}`).then(async (result) => {
      locationDetail[locationid] = result.data;
    });
    return locationDetail[locationid];
  },
};
