var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"headerElement",staticClass:"header",class:{ tablet: _vm.isTablet },attrs:{"data-swiftype-index":"false"}},[_c('div',{staticClass:"main-list"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"left-nav"},[_c('router-link',{staticClass:"logo-container",attrs:{"to":{ name: "home-en" },"aria-label":"Electrify America Home page"}},[_c('logo',{staticStyle:{"width":"auto"}})],1),(_vm.navDropdownIsOpen)?_c('div',{staticClass:"pagemask",on:{"click":function($event){_vm.navDropdownIsOpen = !_vm.navDropdownIsOpen}}}):_vm._e(),_c('nav',{staticClass:"nav-container",attrs:{"aria-label":"Header Primary Site Links"}},[(!_vm.searchActive)?_c('ul',{ref:"nav",staticClass:"nav"},[_vm._l((_vm.headerNavItems),function(navItem,index){return [_c('li',{key:index,staticClass:"label",attrs:{"role":navItem.nestedLinks && navItem.nestedLinks.length > 0 ? null : null,"aria-level":"2"},on:{"keydown":function($event){return _vm.handleMegamenuNavigation(navItem, $event)}}},[(navItem.nestedLinks.length)?_c('button',{class:{
                    'label-name': true,
                    'on-nested-page':
                      navItem.nestedLinks.map(function (link) { return ((link.linkTo) + "-en"); }).includes(_vm.$route.name) &&
                      !_vm.navDropdownIsOpen,
                    open: navItem.name === _vm.activeTopLevelNav && _vm.navDropdownIsOpen,
                  },attrs:{"aria-expanded":navItem.name === _vm.activeTopLevelNav && _vm.navDropdownIsOpen,"id":((navItem.name.toLowerCase().replace(' ', '-')) + "-trigger")},on:{"mousedown":function($event){return _vm.toggleDropdown(navItem.name)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.toggleDropdown(navItem.name)},"focus":function($event){return _vm.updateNavItemFocus(navItem)}}},[_c('span',[_vm._v(_vm._s(navItem.name))]),_c('span',{staticClass:"label-caret"},[_c('HeaderCaret',{attrs:{"color":(navItem.name === _vm.activeTopLevelNav && _vm.navDropdownIsOpen) ||
                        (navItem.nestedLinks.map(function (link) { return ((link.linkTo) + "-en"); }).includes(_vm.$route.name) &&
                          !_vm.navDropdownIsOpen)
                          ? '#35bdd1'
                          : '#fff'}})],1)]):_c('router-link',{class:{
                    'label-name': true,
                    'active-page': !_vm.navDropdownIsOpen && _vm.$route.name === ((navItem.linkTo) + "-en"),
                  },attrs:{"to":{ name: ((navItem.linkTo) + "-en") },"event":navItem.links ? '' : 'click'}},[_vm._v(" "+_vm._s(navItem.name)+" ")]),(navItem.nestedLinks && navItem.nestedLinks.length > 0)?[_c('div',{class:{
                      'nav-dropdown': true,
                      'nav-dropdown--show': navItem.name === _vm.activeTopLevelNav && _vm.navDropdownIsOpen,
                    },attrs:{"role":"region","aria-labelledby":((navItem.name.toLowerCase().replace(' ', '-')) + "-trigger")}},[_c('div',{staticClass:"nav-dropdown__container"},[_c('ul',{staticClass:"nav-dropdown__list"},_vm._l((navItem.nestedLinks),function(link,index){return _c('li',{key:index,class:{
                            'nav-dropdown__item': true,
                            'nav-dropdown__item--active': _vm.activeNavDropdownLinkIndex === index,
                          }},[_c(link.isExternal ? 'a' : 'router-link',_vm._b({tag:"component",class:{
                              'nav-dropdown__link': true,
                              'nav-dropdown__link--active': _vm.activeNavDropdownLinkIndex === index,
                            },attrs:{"tabindex":"-1","target":link.isExternal ? '_blank' : null},nativeOn:{"click":function($event){_vm.navDropdownIsOpen = false}}},'component',link.isExternal ? { href: link.linkTo } : { to: ("/" + (link.linkTo)) },false),[_c('div',{staticClass:"nav-dropdown__item-icon"},[_c('img',{attrs:{"src":require(("@/assets/images/Header/" + (link.icon) + ".png")),"alt":""}})]),_c('div',{staticClass:"nav-dropdown__item-content"},[_c('h4',{class:{
                                  'active-link': _vm.$route.name === ((link.linkTo) + "-en"),
                                }},[_vm._v(" "+_vm._s(link.name)+" ")]),_c('p',[_vm._v(_vm._s(link.description))]),(link.isExternal)?_c('img',{staticClass:"nav-dropdown__external-icon",attrs:{"src":require("@/assets/images/Global/external-link.png"),"alt":"External link"}}):_vm._e()])])],1)}),0)])])]:_vm._e()],2)]})],2):_vm._e(),_c('HeaderSearch',{ref:"searchComponent",attrs:{"headerNavItems":_vm.headerNavItems,"searchActive":_vm.searchActive,"closeSearchbar":_vm.closeSearchbar}})],1)],1),_c('div',{staticClass:"right-nav"},[_c('div',{staticClass:"search"},[(!_vm.searchActive)?_c('button',{staticClass:"search-button",attrs:{"aria-label":"Search"},on:{"click":_vm.openSearchbar}},[_c('searchSVG',{staticStyle:{"height":"20px"},attrs:{"aria-hidden":"true"}})],1):_vm._e(),(_vm.searchActive)?_c('button',{staticClass:"search-button",attrs:{"aria-label":"Close search"},on:{"click":_vm.closeSearchbar}},[_c('searchCloseSVG',{staticStyle:{"height":"20px"}})],1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }