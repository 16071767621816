const Home = () => import('@/views/Home.vue');
const LocateCharger = () => import('@/views/LocateCharger.vue');
const Locations = () => import('@/views/Locations.vue');
const StatePage = () => import('@/views/StatePage.vue');
const CityPage = () => import('@/views/CityPage.vue');
const RenewableEnergy = () => import('@/views/RenewableEnergy.vue');
const SubmissionsThanks = () => import('@/views/SubmissionsThanks.vue');
const ThankYou = () => import('@/views/ThankYou.vue');
const Thanks = () => import('@/views/Thanks.vue');
const VerifyEmailContactUs = () => import('@/views/VerifyEmailContactUs.vue');
const VerifyEmailSignup = () => import('@/views/VerifyEmailSignup.vue');
const EmailVerification = () => import('@/views/EmailVerification.vue');
const Unsubscribe = () => import('@/views/Unsubscribe.vue');
const ExpiredEmail = () => import('@/views/ExpiredEmail.vue');
const SubscriptionAgreement = () => import('@/views/SubscriptionAgreement.vue');
const SocialMediaHouseRules = () => import('@/views/SocialMediaHouseRules.vue');
const AggregateFAQ = () => import('@/views/AggregateFAQ.vue');
const ChargingYourEv = () => import('@/views/ChargingYourEv.vue');
const FourOhFour = () => import('@/views/404.vue');
const PremiumOffers = () => import('@/views/PremiumOffers.vue');
const PlugAndCharge = () => import('@/views/PlugAndCharge.vue');
const SOCPilot = () => import('@/views/SOCPilot.vue');

const routes = [
  {
    path: '/',
    name: 'home-en',
    component: Home,
  },
  {
    path: '/locate-charger/',
    name: 'locate-charger-en',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },
  {
    path: '/locations/',
    name: 'locations-en',
    pathToRegexpOptions: { strict: true },
    component: Locations,
    children: [
      {
        path: ':state/',
        name: 'locations-state-en',
        pathToRegexpOptions: { strict: true },
        component: StatePage,
        children: [
          {
            path: ':city/',
            name: 'locations-state-cities-en',
            pathToRegexpOptions: { strict: true },
            component: CityPage,
          },
        ],
      },
    ],
  },
  {
    path: '/locate-charger/:state?/:city?/:address?/:id?/',
    name: 'locate-charger-unique-url-en',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },
  {
    path: '/renewable-energy/',
    name: 'renewable-energy-en',
    pathToRegexpOptions: { strict: true },
    component: RenewableEnergy,
  },

  {
    path: '/charging-your-ev/',
    name: 'charging-your-ev-en',
    pathToRegexpOptions: { strict: true },
    component: ChargingYourEv,
  },
  {
    path: '/thank-you/',
    name: 'thank-you-en',
    pathToRegexpOptions: { strict: true },
    component: ThankYou,
  },
  {
    path: '/act-expo-thank-you/',
    name: 'thanks-en',
    pathToRegexpOptions: { strict: true },
    component: Thanks,
  },
  {
    path: '/social-media-house-rules/',
    name: 'social-media-house-rules-en',
    pathToRegexpOptions: { strict: true },
    component: SocialMediaHouseRules,
  },
  {
    path: '/subscription-agreement/',
    name: 'subscription-agreement-en',
    pathToRegexpOptions: { strict: true },
    component: SubscriptionAgreement,
  },
  {
    path: '/submissions/:proposal-id',
    name: 'submissions-thanks-en',
    component: SubmissionsThanks,
  },
  {
    path: '/email-verification-contact-us/',
    name: 'email-verification-contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailContactUs,
  },
  {
    path: '/email-verification/',
    name: 'email-verification-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailSignup,
  },
  {
    path: '/inapp/validate-email/',
    name: 'email-validate-en',
    pathToRegexpOptions: { strict: true },
    component: EmailVerification,
  },
  {
    path: '/unsubscribe/',
    name: 'unsubscribe-en',
    pathToRegexpOptions: { strict: true },
    component: Unsubscribe,
  },
  {
    path: '/expired-email/',
    name: 'expired-email-en',
    pathToRegexpOptions: { strict: true },
    component: ExpiredEmail,
  },
  {
    path: '/mobile-faq/',
    name: 'mobile-faq-en',
    pathToRegexpOptions: { strict: true },
    component: AggregateFAQ,
  },
  {
    path: '/mobile-faq/webscreens/',
    name: 'mobile-faq-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: AggregateFAQ,
  },
  {
    path: '/404/',
    name: '404-en',
    pathToRegexpOptions: { strict: true },
    component: FourOhFour,
  },
  {
    path: '/premium-offers/',
    name: 'premium-offers-en',
    pathToRegexpOptions: { strict: true },
    component: PremiumOffers,
  },
  {
    path: '/plug-and-charge/',
    name: 'plug-and-charge-en',
    pathToRegexpOptions: { strict: true },
    component: PlugAndCharge,
  },
  {
    path: '/soc-pilot/',
    name: 'soc-pilot-en',
    pathToRegexpOptions: { strict: true },
    component: SOCPilot,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
