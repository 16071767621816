const BMW = () => import('@/views/Partners/BMW.vue');
const Audi = () => import('@/views/Partners/Audi.vue');
const Genesis = () => import('@/views/Partners/Genesis.vue');
const HarleyDavidsonPartner = () => import('@/views/Partners/Harley-Davidson.vue');
const Hyundai = () => import('@/views/Partners/Hyundai.vue');
const Kia = () => import('@/views/Partners/Kia.vue');
const Lyft = () => import('@/views/Partners/Lyft.vue');
const Volvo = () => import('@/views/Partners/Volvo.vue');
const VW = () => import('@/views/Partners/VW.vue');

const routes = [
  {
    path: '/bmw/',
    name: 'bmw-en',
    pathToRegexpOptions: { strict: true },
    component: BMW,
  },
  {
    path: '/genesis/',
    name: 'genesis-en',
    pathToRegexpOptions: { strict: true },
    component: Genesis,
  },
  {
    path: '/audi/',
    name: 'audi-en',
    pathToRegexpOptions: { strict: true },
    component: Audi,
  },
  {
    path: '/harley-davidson/',
    name: 'harley-davidson-en',
    pathToRegexpOptions: { strict: true },
    component: HarleyDavidsonPartner,
  },
  {
    path: '/hyundai/',
    name: 'hyundai-en',
    pathToRegexpOptions: { strict: true },
    component: Hyundai,
  },
  {
    path: '/kia/',
    name: 'kia-en',
    pathToRegexpOptions: { strict: true },
    component: Kia,
  },
  { path: '/lyft/', name: 'lyft-en', pathToRegexpOptions: { strict: true }, component: Lyft },
  {
    path: '/polestar2/',
    redirect: '/premium-offers/',
  },
  {
    path: '/volvo/',
    name: 'volvo-en',
    pathToRegexpOptions: { strict: true },
    component: Volvo,
  },
  {
    path: '/vw/',
    name: 'vw-en',
    pathToRegexpOptions: { strict: true },
    component: VW,
  },
];

export default routes;
