// All Corporate Social Responsibility pages & URLs
const CSR2021Q2 = () => import('@/views/CSR/CSR2021Q2.vue');
const OurValues = () => import('@/views/CSR/OurValues.vue');

const routes = [
  {
    path: '/CSR-2021-Q2/',
    name: 'CSR-2021-Q2-en',
    pathToRegexpOptions: { strict: true },
    component: CSR2021Q2,
  },
  {
    path: '/our-values/',
    name: 'our-values-en',
    component: OurValues,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
