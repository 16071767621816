const AboutUs = () => import('@/views/Company/AboutUs.vue');
const BusinessSolutions = () => import('@/views/Company/BusinessSolutions.vue');
const Careers = () => import('@/views/Company/Careers.vue');
const OurPlan = () => import('@/views/Company/OurPlan.vue');
const OurTeam = () => import('@/views/Company/OurTeam.vue');
const EarthWeek = () => import('@/views/Company/EarthWeekPromoOver.vue');
const WhistleBlower = () => import('@/views/Company/WhistleBlower.vue');

const routes = [
  {
    path: '/about-us/',
    name: 'about-us-en',
    pathToRegexpOptions: { strict: true },
    component: AboutUs,
  },
  {
    path: '/business-solutions/',
    name: 'business-solutions-en',
    pathToRegexpOptions: { strict: true },
    component: BusinessSolutions,
  },
  {
    path: '/careers/',
    name: 'careers-en',
    pathToRegexpOptions: { strict: true },
    component: Careers,
  },
  {
    path: '/earth-week/',
    name: 'earth-week-en',
    pathToRegexpOptions: { strict: true },
    component: EarthWeek,
  },
  {
    path: '/whistleblower/',
    name: 'whistleblower-en',
    pathToRegexpOptions: { strict: true },
    component: WhistleBlower,
  },
  {
    path: '/our-plan/',
    name: 'our-plan-en',
    pathToRegexpOptions: { strict: true },
    component: OurPlan,
  },
  {
    path: '/our-team/',
    name: 'our-team-en',
    pathToRegexpOptions: { strict: true },
    component: OurTeam,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
