const AcuraDisclosure = () => import('@/views/Disclosures/AcuraDisclosure.vue');
const AudiFleetDisclosure = () => import('@/views/Disclosures/AudiFleetDisclosure.vue');
const BespokeDisclosureT1 = () => import('@/views/Disclosures/BespokeDisclosureT1.vue');
const BespokeDisclosureT2 = () => import('@/views/Disclosures/BespokeDisclosureT2.vue');
const BespokeDisclosureT3 = () => import('@/views/Disclosures/BespokeDisclosureT3.vue');
const BMW2022ChargingDisclosure = () => import('@/views/Disclosures/BMW2022ChargingDisclosure.vue');
const BMW2022ChargingDisclosure2 = () => import('@/views/Disclosures/BMW2022ChargingDisclosure2.vue');
const BMW2023ChargingDisclosure = () => import('@/views/Disclosures/BMW2023ChargingDisclosure.vue');
const BMW2023ChargingDisclosurei4 = () => import('@/views/Disclosures/BMW2023ChargingDisclosurei4.vue');
const BMW2024ChargingDisclosurei4 = () => import('@/views/Disclosures/BMW2024ChargingDisclosurei4.vue');
const BMW2024ChargingDisclosurei5 = () => import('@/views/Disclosures/BMW2024ChargingDisclosurei5.vue');
const BMW2024ChargingDisclosure = () => import('@/views/Disclosures/BWM2024ChargingDisclosureiX.vue');
const BMW2025ChargingDisclosurei4i5iX = () => import('@/views/Disclosures/BMW2025ChargingDisclosurei4i5iX.vue');
const BMW2025ChargingDisclosurei7 = () => import('@/views/Disclosures/BMW2025ChargingDisclosurei7.vue');
const CaliforniaVoluntaryCarbonMarketDisclosure = () =>
  import('@/views/Disclosures/CaliforniaVoluntaryCarbonMarketDisclosure.vue');
const EvolveNYDisclosure = () => import('@/views/Disclosures/EvolveNYDisclosure.vue');
const RockyMountainPowerDisclosure = () => import('@/views/Disclosures/RockyMountainPowerDisclosure.vue');
const FlexdriveDisclosure = () => import('@/views/Disclosures/Flexdrive-Disclosure.vue');
const GenesisG80Disclosure = () => import('@/views/Disclosures/GenesisG80Disclosure.vue');
const GenesisG80Disclosure2024 = () => import('@/views/Disclosures/GenesisG80Disclosure2024.vue');
const Genesis2025ChargingDisclosure = () => import('@/views/Disclosures/Genesis2025ChargingDisclosure.vue');
const GiftPlan1kEV6 = () => import('@/views/Disclosures/GiftPlan1kEV6.vue');
const GigDisclosure = () => import('@/views/Disclosures/GigDisclosure.vue');
const GV602023ChargingPlanDisclosure = () => import('@/views/Disclosures/GV602023ChargingPlanDisclosure.vue');
const GV602024ChargingPlanDisclosure = () => import('@/views/Disclosures/GV602024ChargingPlanDisclosure.vue');
const GV702023ChargingPlanDisclosure = () => import('@/views/Disclosures/GenesisGV702023ChargingPlan.vue');
const GV702024ChargingPlanDisclosure = () => import('@/views/Disclosures/GV702024ChargingPlanDisclosure.vue');
const HarleyDavidsonDisclosure = () => import('@/views/Disclosures/Harley-Davidson-Disclosure.vue');
const HondaDisclosure = () => import('@/views/Disclosures/HondaDisclosure.vue');
const HyundaiChargingDisclosure = () => import('@/views/Disclosures/HyundaiChargingDisclosure.vue');
const HyundaiPremiumDisclosure = () => import('@/views/Disclosures/Hyundai-Premium-Chargin-Disclosure.vue');
const HyundaiIoniq5Disclosure = () => import('@/views/Disclosures/HyundaiIoniq5Disclosure.vue');
const Hyundai2023Ioniq6Disclosure = () => import('@/views/Disclosures/Hyundai2023Ioniq6Disclosure.vue');
const Hyundai2024Ioniq6Disclosure = () => import('@/views/Disclosures/Hyundai2024Ioniq6Disclosure.vue');
const Hyundai2025Ioniq6Disclosure = () => import('@/views/Disclosures/Hyundai2025Ioniq6Disclosure.vue');
const HyundaiIoniq52024Disclosure = () => import('@/views/Disclosures/HyundaiIoniq52024Disclosure.vue');
const ID42021Disclosure = () => import('@/views/Disclosures/2021ID4Disclosure.vue');
const Kia2022Ev6Disclosure = () => import('@/views/Disclosures/Kia2022Ev6Disclosure.vue');
const Kia2023Ev6Disclosure = () => import('@/views/Disclosures/Kia2023Ev6Disclosure.vue');
const Kia2024Ev6Disclosure = () => import('@/views/Disclosures/Kia2024Ev6Disclosure.vue');
const Kia2024Ev9Disclosure = () => import('@/views/Disclosures/Kia2024Ev9Disclosure.vue');
const LyftStandardDiscountPlanDisclosure = () => import('@/views/Disclosures/LyftStandardDiscountPlanDisclosure.vue');
const LyftGoldandPlatinumDiscountPlanDisclosure = () =>
  import('@/views/Disclosures/LyftGoldandPlatinumDiscountPlanDisclosure.vue');
const MobileTerms = () => import('@/views/Disclosures/MobileTerms.vue');
const MY23BMWi7 = () => import('@/views/Disclosures/MY23BMWi7-disclosure.vue');
const MY24BMWi7 = () => import('@/views/Disclosures/MY24BMWi7-disclosure.vue');
const NiroDisclosure = () => import('@/views/Disclosures/NiroDisclosure.vue');
const NiroDisclosure2024 = () => import('@/views/Disclosures/NiroDisclosure2024.vue');
const PolestarDisclosure = () => import('@/views/Disclosures/PolestarDisclosure.vue');
const Privacy = () => import('@/views/Disclosures/Privacy.vue');
const PromoDisclosure = () => import('@/views/Disclosures/PromoDisclosure.vue');
const Terms = () => import('@/views/Disclosures/Terms.vue');
const VolvoDisclosure = () => import('@/views/Disclosures/VolvoDisclosure.vue');
const VWID42022Disclosure = () => import('@/views/Disclosures/VWID42022Disclosure.vue');
const VolvoDisclosure2023 = () => import('@/views/Disclosures/VolvoDisclosure2023.vue');
const VolvoDisclosure2024 = () => import('@/views/Disclosures/VolvoDisclosure2024.vue');
const VWID42023Disclosure = () => import('@/views/Disclosures/VWID42023Disclosure.vue');
const VWID42024Disclosure = () => import('@/views/Disclosures/VWID42024Disclosure.vue');
const VWIDBuzzDisclosure = () => import('@/views/Disclosures/VWIDBuzzDisclosure.vue');
const VolvoXC40RechargePlanDisclosure2023 = () => import('@/views/Disclosures/VolvoXC40RechargePlanDisclosure2023.vue');

const routes = [
  {
    path: '/audi-fleet-charging-disclosure/',
    name: 'audi-fleet-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: AudiFleetDisclosure,
  },
  {
    path: '/audi-fleet-charging-t1-disclosure/',
    name: 'audi-fleet-charging-t1-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BespokeDisclosureT1,
  },
  {
    path: '/audi-fleet-charging-t2-disclosure/',
    name: 'audi-fleet-charging-t2-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BespokeDisclosureT2,
  },
  {
    path: '/audi-fleet-charging-t3-disclosure/',
    name: 'audi-fleet-charging-t3-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BespokeDisclosureT3,
  },
  {
    path: '/2022-bmw-ix-30-minute-charging-disclosure/',
    name: '2022-bmw-ix-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2022ChargingDisclosure,
  },
  {
    path: '/2024-bmw-i5-30-minute-charging-disclosure/',
    name: '2024-bmw-i5-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosurei5,
  },
  {
    path: '/2024-bmw-i5-30-minute-charging-disclosure/webscreens/',
    name: '2024-bmw-i5-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosurei5,
  },
  {
    path: '/2022-bmw-ix-30-minute-charging-disclosure/webscreens/',
    name: '2022-bmw-ix-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2022ChargingDisclosure,
  },
  {
    path: '/2022-bmw-i4-30-minute-charging-disclosure/',
    name: '2022-bmw-i4-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2022ChargingDisclosure2,
  },
  {
    path: '/2022-bmw-i4-30-minute-charging-disclosure/webscreens/',
    name: '2022-bmw-i4-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2022ChargingDisclosure2,
  },
  {
    path: '/2023-bmw-ix-30-minute-charging-disclosure/',
    name: '2023-bmw-ix-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2023ChargingDisclosure,
  },
  {
    path: '/2023-bmw-ix-30-minute-charging-disclosure/webscreens/',
    name: '2023-bmw-ix-30minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2023ChargingDisclosure,
  },
  {
    path: '/2024-bmw-ix-30-minute-charging-disclosure/',
    name: '2024-bmw-ix-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosure,
  },
  {
    path: '/2024-bmw-ix-30-minute-charging-disclosure/webscreens/',
    name: '2024-bmw-ix-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosure,
  },
  {
    path: '/2023-bmw-i4-30-minute-charging-disclosure/',
    name: '2023-bmw-i4-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2023ChargingDisclosurei4,
  },
  {
    path: '/2023-bmw-i4-30-minute-charging-disclosure/webscreens/',
    name: '2023-bmw-i4-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2023ChargingDisclosurei4,
  },
  {
    path: '/2024-bmw-i4-30-minute-charging-disclosure/',
    name: '2024-bmw-i4-30-minute-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosurei4,
  },
  {
    path: '/2024-bmw-i4-30-minute-charging-disclosure/webscreens/',
    name: '2024-bmw-i4-30-minute-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2024ChargingDisclosurei4,
  },
  {
    path: '/2023-bmw-i7-charging-disclosure/',
    name: '2023-bmw-i7-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: MY23BMWi7,
  },
  {
    path: '/2023-bmw-i7-charging-disclosure/webscreens/',
    name: '2023-bmw-i7-charging-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: MY23BMWi7,
  },
  {
    path: '/2024-bmw-i7-charging-disclosure/',
    name: '2024-bmw-i7-charging-disclosure',
    pathToRegexpOptions: { strict: true },
    component: MY24BMWi7,
  },
  {
    path: '/2024-bmw-i7-charging-disclosure/webscreens/',
    name: '2024-bmw-i7-charging-disclosure-webscreens',
    pathToRegexpOptions: { strict: true },
    component: MY24BMWi7,
  },
  {
    path: '/2025-bmw-i4i5iX-charging-disclosure/',
    name: '2025-bmw-i4i5iX-charging-disclosure',
    pathToRegexpOptions: { strict: true },
    component: BMW2025ChargingDisclosurei4i5iX,
  },
  {
    path: '/2025-bmw-i4i5iX-charging-disclosure/webscreens/',
    name: '2025-bmw-i4i5iX-charging-disclosure-webscreens',
    pathToRegexpOptions: { strict: true },
    component: BMW2025ChargingDisclosurei4i5iX,
  },
  {
    path: '/2025-bmw-i7-charging-disclosure/',
    name: '2025-bmw-i7-charging-disclosure',
    pathToRegexpOptions: { strict: true },
    component: BMW2025ChargingDisclosurei7,
  },
  {
    path: '/2025-bmw-i7-charging-disclosure/webscreens/',
    name: '2025-bmw-i7-charging-disclosure-webscreens',
    pathToRegexpOptions: { strict: true },
    component: BMW2025ChargingDisclosurei7,
  },
  {
    path: '/2025-genesis-charging-plan-disclosure/',
    name: '2025-genesis-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Genesis2025ChargingDisclosure,
  },
  {
    path: '/california-voluntary-carbon-market-disclosure/',
    name: 'california-voluntary-carbon-market-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: CaliforniaVoluntaryCarbonMarketDisclosure,
  },
  {
    path: '/evolve-ny-frequent-driver-disclosure/',
    name: 'evolve-ny-frequent-driver-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: EvolveNYDisclosure,
  },
  {
    path: '/honda-charging-plan-disclosure/',
    name: 'honda-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HondaDisclosure,
  },
  {
    path: '/honda-charging-plan-disclosure/webscreens/',
    name: 'honda-charging-plan-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: HondaDisclosure,
  },
  {
    path: '/rocky-mountain-power-plan-disclosure/',
    name: 'rocky-mountain-power-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: RockyMountainPowerDisclosure,
  },
  {
    path: '/acura-charging-plan-disclosure/',
    name: 'acura-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: AcuraDisclosure,
  },
  {
    path: '/acura-charging-plan-disclosure/webscreens/',
    name: 'acura-charging-plan-disclosure-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: AcuraDisclosure,
  },
  {
    path: '/flexdrive-disclosures/',
    name: 'flexdrive-disclosures-en',
    pathToRegexpOptions: { strict: true },
    component: FlexdriveDisclosure,
  },
  {
    path: '/2023-electrified-g80-plan-disclosure/',
    name: '2023-electrified-g80-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GenesisG80Disclosure,
  },
  {
    path: '/2024-electrified-g80-plan-disclosure/',
    name: '2024-electrified-g80-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GenesisG80Disclosure2024,
  },
  {
    path: '/2023-electrified-gv70-plan-disclosure/',
    name: '2023-electrified-gv70-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GV702023ChargingPlanDisclosure,
  },
  {
    path: '/2024-gv70-charging-plan-disclosure/',
    name: '2024-gv70-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GV702024ChargingPlanDisclosure,
  },
  {
    path: '/ev6-first-edition-1k-gift-plan-disclosure/',
    name: 'ev6-first-edition-1k-gift-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GiftPlan1kEV6,
  },
  {
    path: '/gig-disclosure/',
    name: 'gig-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GigDisclosure,
  },
  {
    path: '/2023-gv60-charging-plan-disclosure/',
    name: '2023-gv60-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GV602023ChargingPlanDisclosure,
  },
  {
    path: '/2024-gv60-charging-plan-disclosure/',
    name: '2024-gv60-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: GV602024ChargingPlanDisclosure,
  },
  {
    path: '/harley-davidson-disclosure/',
    name: 'harley-davidson-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HarleyDavidsonDisclosure,
  },
  {
    path: '/hyundai-ioniq5-charging-disclosure/',
    name: 'hyundai-ioniq5-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HyundaiChargingDisclosure,
  },
  {
    path: '/hyundai-premium-charging-disclosure/',
    name: 'hyundai-premium-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HyundaiPremiumDisclosure,
  },
  {
    path: '/2023-ioniq5-charging-plan-disclosure/',
    name: '2023-ioniq5-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HyundaiIoniq5Disclosure,
  },
  {
    path: '/2024-ioniq5-charging-plan-disclosure/',
    name: '2024-ioniq5-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: HyundaiIoniq52024Disclosure,
  },
  {
    path: '/2023-ioniq6-charging-plan-disclosure/',
    name: '2023-ioniq6-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Hyundai2023Ioniq6Disclosure,
  },
  {
    path: '/2024-ioniq6-charging-plan-disclosure/',
    name: '2024-ioniq6-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Hyundai2024Ioniq6Disclosure,
  },
  {
    path: '/2025-ioniq6-charging-plan-disclosure/',
    name: '2025-ioniq6-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Hyundai2025Ioniq6Disclosure,
  },
  {
    path: '/2021-vw-id4-charging-plan-disclosure/',
    name: '2021-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: ID42021Disclosure,
  },
  {
    path: '/lyft-standard-discount-plan-disclosure/',
    name: 'lyft-standard-discount-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: LyftStandardDiscountPlanDisclosure,
  },
  {
    path: '/lyft-gold-and-platinum-discount-plan-disclosure/',
    name: 'lyft-gold-and-platinum-discount-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: LyftGoldandPlatinumDiscountPlanDisclosure,
  },
  {
    path: '/2022-ev6-1k-charge-pass-disclosure/',
    name: '2022-ev6-1k-charge-pass-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Kia2022Ev6Disclosure,
  },
  {
    path: '/2023-ev6-charge-plan-disclosure/',
    name: '2023-ev6-charge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Kia2023Ev6Disclosure,
  },
  {
    path: '/2024-ev6-charge-plan-disclosure/',
    name: '2024-ev6-charge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Kia2024Ev6Disclosure,
  },
  {
    path: '/2024-ev9-charge-plan-disclosure/',
    name: '2024-ev9-charge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: Kia2024Ev9Disclosure,
  },
  {
    path: '/mobile-tou/',
    name: 'mobile-tou',
    pathToRegexpOptions: { strict: true },
    component: MobileTerms,
  },
  {
    path: '/2023-niro-charge-plan-disclosure/',
    name: '2023-niro-charge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: NiroDisclosure,
  },
  {
    path: '/2024-niro-charge-plan-disclosure/',
    name: '2024-niro-charge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: NiroDisclosure2024,
  },
  {
    path: '/polestar2-charging-plan-disclosure/',
    name: 'polestar2-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: PolestarDisclosure,
  },
  {
    path: '/privacy/',
    name: 'privacy-en',
    pathToRegexpOptions: { strict: true },
    component: Privacy,
  },
  {
    path: '/privacy/webscreens/',
    name: 'privacy-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: Privacy,
  },
  {
    path: '/promo-disclosure/',
    name: 'promo-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: PromoDisclosure,
  },
  {
    path: '/terms/',
    name: 'terms-en',
    pathToRegexpOptions: { strict: true },
    component: Terms,
  },
  {
    path: '/terms/webscreens/',
    name: 'terms-webscreens-en',
    pathToRegexpOptions: { strict: true },
    component: Terms,
  },
  {
    path: '/volvo-dc-fast-charging-disclosure/',
    name: 'volvo-dc-fast-charging-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VolvoDisclosure,
  },
  {
    path: '/2023-volvo-c40-recharge-plan-disclosure/',
    name: '2023-volvo-c40-recharge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VolvoDisclosure2023,
  },
  {
    path: '/2024-volvo-charging-plan-disclosure/',
    name: '2024-volvo-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VolvoDisclosure2024,
  },
  {
    path: '/2023-volvo-xc40-recharge-plan-disclosure/',
    name: '2023-volvo-xc40-recharge-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VolvoXC40RechargePlanDisclosure2023,
  },
  {
    path: '/2022-vw-id4-charging-plan-disclosure/',
    name: '2022-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VWID42022Disclosure,
  },
  {
    path: '/2023-vw-id4-charging-plan-disclosure/',
    name: '2023-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VWID42023Disclosure,
  },
  {
    path: '/vw-id4-charging-plan-disclosure/',
    name: '2024-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VWID42024Disclosure,
  },
  {
    path: '/vw-id.buzz-charging-plan-disclosure/',
    name: 'vw-id.buzz-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VWIDBuzzDisclosure,
  },
];

export default routes;
