import Vue from 'vue';
import Vuex from 'vuex';
import locations from './modules/locations';
import map from './modules/map';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentRefID: null,
    email: null,
    lang: 'en',
  },
  mutations: {
    setCurrentRefID(state, payload) {
      state.currentRefID = payload;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {},
  modules: { locations, map },
});
